import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"previous-page-button\">\n  <PixIconButton\n    @icon=\"arrow-left\"\n    aria-label={{@backButtonAriaLabel}}\n    class=\"previous-page-button__return-button hide-on-mobile\"\n    @triggerAction={{this.goToPage}}\n    @withBackground={{false}}\n    @size=\"small\"\n    @color=\"dark-grey\"\n  />\n  <h1 class=\"back-title\" ...attributes>\n    {{yield}}\n  </h1>\n</div>", {"contents":"<div class=\"previous-page-button\">\n  <PixIconButton\n    @icon=\"arrow-left\"\n    aria-label={{@backButtonAriaLabel}}\n    class=\"previous-page-button__return-button hide-on-mobile\"\n    @triggerAction={{this.goToPage}}\n    @withBackground={{false}}\n    @size=\"small\"\n    @color=\"dark-grey\"\n  />\n  <h1 class=\"back-title\" ...attributes>\n    {{yield}}\n  </h1>\n</div>","moduleName":"pix-orga/components/ui/previous-page-button.hbs","parseOptions":{"srcName":"pix-orga/components/ui/previous-page-button.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class PreviousPageButton extends Component {
  @service router;

  @action
  goToPage() {
    if (this.args.routeId) {
      this.router.transitionTo(this.args.route, this.args.routeId);
    } else {
      this.router.transitionTo(this.args.route);
    }
  }
}
