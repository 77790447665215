import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"recommendation-indicator\">\n  <svg height=\"10\" width={{this.bubbleWidth}} aria-label={{this.label}} role=\"img\">\n    {{#each this.bubbles as |bubble index|}}\n      <circle cx={{sum (multiply 12 index) 5}} cy=\"5\" r=\"5\" class=\"recommendation-indicator__bubble\"></circle>\n    {{/each}}\n  </svg>\n</div>", {"contents":"<div class=\"recommendation-indicator\">\n  <svg height=\"10\" width={{this.bubbleWidth}} aria-label={{this.label}} role=\"img\">\n    {{#each this.bubbles as |bubble index|}}\n      <circle cx={{sum (multiply 12 index) 5}} cy=\"5\" r=\"5\" class=\"recommendation-indicator__bubble\"></circle>\n    {{/each}}\n  </svg>\n</div>","moduleName":"pix-orga/components/campaign/analysis/recommendation-indicator.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/analysis/recommendation-indicator.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const RECOMMENDED = 75;
const STRONGLY_RECOMMENDED = 50;
const VERY_STRONGLY_RECOMMENDED = 25;

export default class RecommendationIndicator extends Component {
  @service intl;

  get bubblesCount() {
    const value = this.args.value;
    if (value <= VERY_STRONGLY_RECOMMENDED) return 4;
    if (value <= STRONGLY_RECOMMENDED) return 3;
    if (value <= RECOMMENDED) return 2;
    return 1;
  }

  get bubbles() {
    return new Array(this.bubblesCount);
  }

  get label() {
    const value = this.args.value;
    if (value <= VERY_STRONGLY_RECOMMENDED)
      return this.intl.t('pages.campaign-review.table.analysis.recommendations.very-strongly-recommended');
    if (value <= STRONGLY_RECOMMENDED)
      return this.intl.t('pages.campaign-review.table.analysis.recommendations.strongly-recommended');
    if (value <= RECOMMENDED) return this.intl.t('pages.campaign-review.table.analysis.recommendations.recommended');
    return this.intl.t('pages.campaign-review.table.analysis.recommendations.moderately-recommended');
  }

  get bubbleWidth() {
    return 12 * this.bubblesCount;
  }

  get xBubbleCoordinate() {
    return 5 * this.bubbles.length;
  }
}
