import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"panel empty-state\">\n  <img src=\"{{this.rootURL}}/images/empty-state-activity.svg\" alt=\"\" role=\"none\" />\n\n  <div class=\"empty-state__text\">\n    <p>{{t\n        \"pages.organization-learner.activity.empty-state\"\n        organizationLearnerFirstName=this.firstName\n        organizationLearnerLastName=this.lastName\n      }}</p>\n  </div>\n</section>", {"contents":"<section class=\"panel empty-state\">\n  <img src=\"{{this.rootURL}}/images/empty-state-activity.svg\" alt=\"\" role=\"none\" />\n\n  <div class=\"empty-state__text\">\n    <p>{{t\n        \"pages.organization-learner.activity.empty-state\"\n        organizationLearnerFirstName=this.firstName\n        organizationLearnerLastName=this.lastName\n      }}</p>\n  </div>\n</section>","moduleName":"pix-orga/components/organization-learner/activity/empty-state.hbs","parseOptions":{"srcName":"pix-orga/components/organization-learner/activity/empty-state.hbs"}});
import Component from '@glimmer/component';
import capitalize from 'lodash/capitalize';

export default class EmptyState extends Component {
  get firstName() {
    const { firstName } = this.args;
    return capitalize(firstName);
  }

  get lastName() {
    const { lastName } = this.args;
    return capitalize(lastName);
  }
}
