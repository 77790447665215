import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"panel table content-text content-text--small\">\n  <table>\n    <thead>\n      <tr>\n        <Table::Header @size=\"wide\">{{t \"pages.team-invitations.table.column.email-address\"}}</Table::Header>\n        <Table::Header @size=\"x-wide\" class=\"hide-on-mobile\">{{t\n            \"pages.team-invitations.table.column.pending-invitation\"\n          }}</Table::Header>\n        <Table::Header @size=\"wide\" class=\"hide-on-mobile\">\n          <span>{{t \"common.actions.global\"}}</span>\n        </Table::Header>\n      </tr>\n    </thead>\n    <tbody>\n      {{#each @invitations as |invitation|}}\n        <Team::InvitationsListItem @invitation={{invitation}} @cancelInvitation={{this.cancelInvitation}} />\n      {{/each}}\n    </tbody>\n  </table>\n</div>", {"contents":"<div class=\"panel table content-text content-text--small\">\n  <table>\n    <thead>\n      <tr>\n        <Table::Header @size=\"wide\">{{t \"pages.team-invitations.table.column.email-address\"}}</Table::Header>\n        <Table::Header @size=\"x-wide\" class=\"hide-on-mobile\">{{t\n            \"pages.team-invitations.table.column.pending-invitation\"\n          }}</Table::Header>\n        <Table::Header @size=\"wide\" class=\"hide-on-mobile\">\n          <span>{{t \"common.actions.global\"}}</span>\n        </Table::Header>\n      </tr>\n    </thead>\n    <tbody>\n      {{#each @invitations as |invitation|}}\n        <Team::InvitationsListItem @invitation={{invitation}} @cancelInvitation={{this.cancelInvitation}} />\n      {{/each}}\n    </tbody>\n  </table>\n</div>","moduleName":"pix-orga/components/team/invitations-list.hbs","parseOptions":{"srcName":"pix-orga/components/team/invitations-list.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TeamInvitationsListComponent extends Component {
  @service store;
  @service notifications;
  @service currentUser;
  @service intl;

  @action
  async cancelInvitation(organizationInvitation) {
    try {
      const organizationId = this.currentUser.organization.id;

      organizationInvitation.deleteRecord();
      await organizationInvitation.save({
        adapterOptions: { organizationInvitationId: organizationInvitation.id, organizationId },
      });

      this.notifications.success(this.intl.t('pages.team-invitations.invitation-cancelled-succeed-message'));
    } catch (e) {
      this.notifications.error(this.intl.t('api-error-messages.global'));
    }
  }
}
