import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n  <div class=\"divisions-filter--is-loading placeholder-box\"></div>\n{{else}}\n  <PixMultiSelect\n    @label={{t \"common.filters.divisions.title\"}}\n    @placeholder={{@placeholder}}\n    @emptyMessage={{t \"common.filters.divisions.empty\"}}\n    @screenReaderOnly={{true}}\n    @values={{@selected}}\n    @onChange={{@onSelect}}\n    @options={{this.options}}\n    @isSearchable={{true}}\n    ...attributes\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n{{/if}}", {"contents":"{{#if this.isLoading}}\n  <div class=\"divisions-filter--is-loading placeholder-box\"></div>\n{{else}}\n  <PixMultiSelect\n    @label={{t \"common.filters.divisions.title\"}}\n    @placeholder={{@placeholder}}\n    @emptyMessage={{t \"common.filters.divisions.empty\"}}\n    @screenReaderOnly={{true}}\n    @values={{@selected}}\n    @onChange={{@onSelect}}\n    @options={{this.options}}\n    @isSearchable={{true}}\n    ...attributes\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n{{/if}}","moduleName":"pix-orga/components/ui/divisions-filter.hbs","parseOptions":{"srcName":"pix-orga/components/ui/divisions-filter.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class DivisionsFilter extends Component {
  @tracked isLoading;

  constructor() {
    super(...arguments);

    this.isLoading = true;
    this.args.model.divisions.then(() => {
      this.isLoading = false;
    });
  }

  get options() {
    return this.args.model.divisions?.map(({ name }) => ({ value: name, label: name }));
  }
}
