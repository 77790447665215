import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"campaign-type\">\n  <FaIcon class=\"{{this.pictoCssClass}}\" @icon=\"{{this.picto}}\" @prefix=\"fapix\" />\n  <span class=\"campaign-type__label\">{{this.label}}</span>\n</div>", {"contents":"<div class=\"campaign-type\">\n  <FaIcon class=\"{{this.pictoCssClass}}\" @icon=\"{{this.picto}}\" @prefix=\"fapix\" />\n  <span class=\"campaign-type__label\">{{this.label}}</span>\n</div>","moduleName":"pix-orga/components/organization-learner/activity/campaign-type.hbs","parseOptions":{"srcName":"pix-orga/components/organization-learner/activity/campaign-type.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class CampaignType extends Component {
  @service intl;

  get picto() {
    const { campaignType } = this.args;
    return campaignType === 'ASSESSMENT' ? 'tachometer' : 'person-export';
  }

  get pictoCssClass() {
    const { campaignType } = this.args;
    return campaignType === 'ASSESSMENT' ? 'campaign-type__icon-assessment' : 'campaign-type__icon-profile-collection';
  }

  get label() {
    const { campaignType } = this.args;
    return this.intl.t(`pages.organization-learner.activity.participation-list.type.${campaignType}`);
  }
}
