import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isLoading}}\n  <div class=\"multi-select-filter--is-loading placeholder-box\"></div>\n{{else}}\n  <PixMultiSelect\n    @label={{@title}}\n    @placeholder={{@placeholder}}\n    @screenReaderOnly={{true}}\n    @emptyMessage={{@emptyMessage}}\n    @isSearchable={{true}}\n    @onChange={{this.onSelect}}\n    @values={{@selectedOption}}\n    @options={{@options}}\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n{{/if}}", {"contents":"{{#if @isLoading}}\n  <div class=\"multi-select-filter--is-loading placeholder-box\"></div>\n{{else}}\n  <PixMultiSelect\n    @label={{@title}}\n    @placeholder={{@placeholder}}\n    @screenReaderOnly={{true}}\n    @emptyMessage={{@emptyMessage}}\n    @isSearchable={{true}}\n    @onChange={{this.onSelect}}\n    @values={{@selectedOption}}\n    @options={{@options}}\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n{{/if}}","moduleName":"pix-orga/components/ui/multi-select-filter.hbs","parseOptions":{"srcName":"pix-orga/components/ui/multi-select-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class MultiSelectFilter extends Component {
  @action
  onSelect(value) {
    const { onSelect, field } = this.args;
    onSelect(field, value);
  }
}
