import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"no-participant-panel panel\">\n  <img src=\"{{this.rootURL}}/images/empty-state-participants.svg\" alt=\"\" role=\"none\" />\n  <p class=\"content-text\">\n    {{t \"pages.organization-participants.empty-state.message\"}}\n    {{#if this.isNotManagingStudents}}\n      <br />\n      {{t \"pages.organization-participants.empty-state.call-to-action\"}}\n      <LinkTo @route=\"authenticated.campaigns.list.my-campaigns\" class=\"link link--underlined\">\n        {{t \"pages.organization-participants.empty-state.action\"}}\n      </LinkTo>\n    {{/if}}\n  </p>\n</section>", {"contents":"<section class=\"no-participant-panel panel\">\n  <img src=\"{{this.rootURL}}/images/empty-state-participants.svg\" alt=\"\" role=\"none\" />\n  <p class=\"content-text\">\n    {{t \"pages.organization-participants.empty-state.message\"}}\n    {{#if this.isNotManagingStudents}}\n      <br />\n      {{t \"pages.organization-participants.empty-state.call-to-action\"}}\n      <LinkTo @route=\"authenticated.campaigns.list.my-campaigns\" class=\"link link--underlined\">\n        {{t \"pages.organization-participants.empty-state.action\"}}\n      </LinkTo>\n    {{/if}}\n  </p>\n</section>","moduleName":"pix-orga/components/organization-participant/no-participant-panel.hbs","parseOptions":{"srcName":"pix-orga/components/organization-participant/no-participant-panel.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class List extends Component {
  @service currentUser;

  get isNotManagingStudents() {
    return !this.currentUser.isSCOManagingStudents && !this.currentUser.isSUPManagingStudents;
  }
}
