import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<footer class=\"footer\">\n  <nav class=\"footer__navigation\" aria-label={{t \"navigation.footer.aria-label\"}}>\n    <ul class=\"footer__navigation-list\">\n      <li>\n        <a href=\"{{this.legalNoticeUrl}}\" target=\"_blank\" class=\"footer-navigation__item\" rel=\"noopener noreferrer\">\n          {{t \"navigation.footer.legal-notice\"}}\n        </a>\n      </li>\n\n      <li>\n        <a href=\"{{this.accessibilityUrl}}\" target=\"_blank\" class=\"footer-navigation__item\" rel=\"noopener noreferrer\">\n          {{t \"navigation.footer.a11y\"}}\n        </a>\n      </li>\n    </ul>\n  </nav>\n\n  <div class=\"footer__copyrights\">\n    <span>{{t \"navigation.footer.copyrights\"}} {{this.currentYear}} {{t \"navigation.footer.pix\"}}</span>\n  </div>\n</footer>", {"contents":"<footer class=\"footer\">\n  <nav class=\"footer__navigation\" aria-label={{t \"navigation.footer.aria-label\"}}>\n    <ul class=\"footer__navigation-list\">\n      <li>\n        <a href=\"{{this.legalNoticeUrl}}\" target=\"_blank\" class=\"footer-navigation__item\" rel=\"noopener noreferrer\">\n          {{t \"navigation.footer.legal-notice\"}}\n        </a>\n      </li>\n\n      <li>\n        <a href=\"{{this.accessibilityUrl}}\" target=\"_blank\" class=\"footer-navigation__item\" rel=\"noopener noreferrer\">\n          {{t \"navigation.footer.a11y\"}}\n        </a>\n      </li>\n    </ul>\n  </nav>\n\n  <div class=\"footer__copyrights\">\n    <span>{{t \"navigation.footer.copyrights\"}} {{this.currentYear}} {{t \"navigation.footer.pix\"}}</span>\n  </div>\n</footer>","moduleName":"pix-orga/components/layout/footer.hbs","parseOptions":{"srcName":"pix-orga/components/layout/footer.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Footer extends Component {
  @service url;

  get currentYear() {
    const date = new Date();
    return date.getFullYear().toString();
  }

  get legalNoticeUrl() {
    return this.url.legalNoticeUrl;
  }

  get accessibilityUrl() {
    return this.url.accessibilityUrl;
  }
}
