import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"panel empty-state\">\n  <img src=\"{{this.rootURL}}/images/empty-state.svg\" alt=\"\" role=\"none\" />\n\n  <div class=\"empty-state__text\">\n    <p>{{t \"pages.campaign.empty-state\"}}</p>\n\n    <Campaign::CopyPasteButton\n      @clipBoardtext={{this.campaignUrl}}\n      @successMessage={{t \"pages.campaign.copy.link.success\"}}\n      @defaultMessage={{t \"pages.campaign.copy.link.default\"}}\n    />\n  </div>\n</section>", {"contents":"<section class=\"panel empty-state\">\n  <img src=\"{{this.rootURL}}/images/empty-state.svg\" alt=\"\" role=\"none\" />\n\n  <div class=\"empty-state__text\">\n    <p>{{t \"pages.campaign.empty-state\"}}</p>\n\n    <Campaign::CopyPasteButton\n      @clipBoardtext={{this.campaignUrl}}\n      @successMessage={{t \"pages.campaign.copy.link.success\"}}\n      @defaultMessage={{t \"pages.campaign.copy.link.default\"}}\n    />\n  </div>\n</section>","moduleName":"pix-orga/components/campaign/empty-state.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/empty-state.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class EmptyState extends Component {
  @service url;

  get campaignUrl() {
    return `${this.url.campaignsRootUrl}${this.args.campaignCode}`;
  }
}
