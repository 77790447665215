import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (is-clipboard-supported)}}\n  <PixTooltip\n    @id=\"copy-paste-button\"\n    @position=\"top\"\n    @isInline={{true}}\n    class=\"copy-paste-button__tooltip hide-on-mobile\"\n  >\n    <:triggerElement>\n      <CopyButton\n        aria-label={{@defaultMessage}}\n        aria-describedby=\"copy-paste-button\"\n        @clipboardText={{@clipBoardtext}}\n        @success={{this.onClipboardSuccess}}\n        {{on \"mouseLeave\" this.onClipboardOut}}\n        class=\"pix-icon-button pix-icon-button--small pix-icon-button--dark-grey copy-paste-button__clipboard\"\n        ...attributes\n      >\n        <FaIcon @icon=\"copy\" @prefix=\"far\" />\n      </CopyButton>\n    </:triggerElement>\n    <:tooltip>\n      {{this.tooltipText}}\n    </:tooltip>\n  </PixTooltip>\n{{/if}}", {"contents":"{{#if (is-clipboard-supported)}}\n  <PixTooltip\n    @id=\"copy-paste-button\"\n    @position=\"top\"\n    @isInline={{true}}\n    class=\"copy-paste-button__tooltip hide-on-mobile\"\n  >\n    <:triggerElement>\n      <CopyButton\n        aria-label={{@defaultMessage}}\n        aria-describedby=\"copy-paste-button\"\n        @clipboardText={{@clipBoardtext}}\n        @success={{this.onClipboardSuccess}}\n        {{on \"mouseLeave\" this.onClipboardOut}}\n        class=\"pix-icon-button pix-icon-button--small pix-icon-button--dark-grey copy-paste-button__clipboard\"\n        ...attributes\n      >\n        <FaIcon @icon=\"copy\" @prefix=\"far\" />\n      </CopyButton>\n    </:triggerElement>\n    <:tooltip>\n      {{this.tooltipText}}\n    </:tooltip>\n  </PixTooltip>\n{{/if}}","moduleName":"pix-orga/components/campaign/copy-paste-button.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/copy-paste-button.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CopyPasteButton extends Component {
  @tracked tooltipText;

  constructor() {
    super(...arguments);
    this.tooltipText = this.args.defaultMessage;
  }

  @action
  onClipboardSuccess() {
    this.tooltipText = this.args.successMessage;
  }

  @action
  onClipboardOut() {
    this.tooltipText = this.args.defaultMessage;
  }
}
