import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Header @size={{@size}} @align={{@align}} aria-sort={{this.order}} ...attributes>\n  <span class=\"table__column--sort\">\n    {{yield}}\n    {{#unless @isDisabled}}\n      <PixIconButton\n        @icon={{this.icon}}\n        @triggerAction={{this.toggleSort}}\n        @withBackground={{false}}\n        @size=\"small\"\n        @color=\"dark-grey\"\n        aria-label={{this.ariaLabel}}\n      />\n    {{/unless}}\n  </span>\n</Table::Header>", {"contents":"<Table::Header @size={{@size}} @align={{@align}} aria-sort={{this.order}} ...attributes>\n  <span class=\"table__column--sort\">\n    {{yield}}\n    {{#unless @isDisabled}}\n      <PixIconButton\n        @icon={{this.icon}}\n        @triggerAction={{this.toggleSort}}\n        @withBackground={{false}}\n        @size=\"small\"\n        @color=\"dark-grey\"\n        aria-label={{this.ariaLabel}}\n      />\n    {{/unless}}\n  </span>\n</Table::Header>","moduleName":"pix-orga/components/table/header-sort.hbs","parseOptions":{"srcName":"pix-orga/components/table/header-sort.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class HeaderSort extends Component {
  @tracked order;
  @tracked icon;
  @tracked ariaLabel;

  constructor(...args) {
    super(...args);
    if (this.args.defaultOrder === 'desc') {
      this._setDescendantSort();
    } else if (this.args.defaultOrder === 'asc') {
      this._setAscendantSort();
    } else {
      this._setDefaultSort();
    }
  }

  @action
  toggleSort() {
    if (this.order === 'asc') {
      this._setDescendantSort();
    } else {
      this._setAscendantSort();
    }
    this.args.onSort(this.order);
  }

  _setDefaultSort() {
    this.order = null;
    this.icon = 'sort';
    this.ariaLabel = this.args.ariaLabelDefaultSort;
  }

  _setAscendantSort() {
    this.order = 'asc';
    this.icon = 'sort-up';
    this.ariaLabel = this.args.ariaLabelSortUp;
  }

  _setDescendantSort() {
    this.order = 'desc';
    this.icon = 'sort-down';
    this.ariaLabel = this.args.ariaLabelSortDown;
  }
}
