import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixSelect\n  @label={{@ariaLabel}}\n  @screenReaderOnly={{true}}\n  @onChange={{this.onChange}}\n  @options={{@options}}\n  @value={{@selectedOption}}\n  @placeholder={{@emptyOptionLabel}}\n/>", {"contents":"<PixSelect\n  @label={{@ariaLabel}}\n  @screenReaderOnly={{true}}\n  @onChange={{this.onChange}}\n  @options={{@options}}\n  @value={{@selectedOption}}\n  @placeholder={{@emptyOptionLabel}}\n/>","moduleName":"pix-orga/components/ui/select-filter.hbs","parseOptions":{"srcName":"pix-orga/components/ui/select-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SelectFilter extends Component {
  @action
  onChange(value) {
    const { triggerFiltering, field } = this.args;
    triggerFiltering(field, value);
  }
}
