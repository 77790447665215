import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<th scope=\"row\" rowspan={{@thematic.tubes.length}}>\n  <Input\n    {{did-insert this.updateState}}\n    {{did-update this.updateState this.state}}\n    id=\"thematic-{{@thematic.id}}\"\n    @type=\"checkbox\"\n    @checked={{this.checked}}\n  />\n  <label for=\"thematic-{{@thematic.id}}\">\n    {{@thematic.name}}\n  </label>\n</th>", {"contents":"<th scope=\"row\" rowspan={{@thematic.tubes.length}}>\n  <Input\n    {{did-insert this.updateState}}\n    {{did-update this.updateState this.state}}\n    id=\"thematic-{{@thematic.id}}\"\n    @type=\"checkbox\"\n    @checked={{this.checked}}\n  />\n  <label for=\"thematic-{{@thematic.id}}\">\n    {{@thematic.name}}\n  </label>\n</th>","moduleName":"pix-orga/components/tube/thematic.hbs","parseOptions":{"srcName":"pix-orga/components/tube/thematic.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class Thematic extends Component {
  @action
  updateState(element) {
    element.indeterminate = this.state === 'indeterminate';
  }

  get state() {
    return this.args.getThematicState(this.args.thematic);
  }

  get checked() {
    return this.state === 'checked';
  }

  set checked(checked) {
    if (checked) {
      this.args.selectThematic(this.args.thematic);
    } else {
      this.args.unselectThematic(this.args.thematic);
    }
  }
}
