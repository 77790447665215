import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"organization-participant-list-page__header\">\n  <h1 class=\"page__title page-title\">{{t \"pages.sco-organization-participants.title\" count=@participantCount}}</h1>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"organization-participant-list-page__import-students-button hide-on-mobile\">\n      <PixButtonUpload @id=\"students-file-upload\" @onChange={{@onImportStudents}} accept={{this.acceptedFileType}}>\n        {{this.importButtonLabel}}\n      </PixButtonUpload>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"organization-participant-list-page__header\">\n  <h1 class=\"page__title page-title\">{{t \"pages.sco-organization-participants.title\" count=@participantCount}}</h1>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"organization-participant-list-page__import-students-button hide-on-mobile\">\n      <PixButtonUpload @id=\"students-file-upload\" @onChange={{@onImportStudents}} accept={{this.acceptedFileType}}>\n        {{this.importButtonLabel}}\n      </PixButtonUpload>\n    </div>\n  {{/if}}\n</div>","moduleName":"pix-orga/components/sco-organization-participant/header-actions.hbs","parseOptions":{"srcName":"pix-orga/components/sco-organization-participant/header-actions.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ScoHeaderActions extends Component {
  @service currentUser;
  @service session;
  @service intl;

  get acceptedFileType() {
    if (this.currentUser.isAgriculture) {
      return ['.csv'];
    }
    return ['.xml', '.zip'];
  }

  get importButtonLabel() {
    const types = this.acceptedFileType.join(
      this.intl.t('pages.sco-organization-participants.actions.import-file.file-type-separator')
    );
    return this.intl.t('pages.sco-organization-participants.actions.import-file.label', { types });
  }
}
