import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"dropdown__item dropdown__item--link\" {{on \"keyup\" this.handleKeyUp}} ...attributes>\n  <button type=\"button\" {{on \"click\" @onClick}}>\n    {{yield}}\n  </button>\n</li>", {"contents":"<li class=\"dropdown__item dropdown__item--link\" {{on \"keyup\" this.handleKeyUp}} ...attributes>\n  <button type=\"button\" {{on \"click\" @onClick}}>\n    {{yield}}\n  </button>\n</li>","moduleName":"pix-orga/components/dropdown/item.hbs","parseOptions":{"srcName":"pix-orga/components/dropdown/item.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class DropdownItem extends Component {
  @action
  handleKeyUp(event) {
    if (event.key === 'Enter') {
      this.args.onClick();
    }
  }
}
